.container-side {
  background-color: white;
  width: 300px;
  height: 400px;
  border-radius: 8px 0 0 8px;
  position: fixed;
  top: 20%;
  right: 0px;
  z-index: 99999;
  animation: slide 500ms 500ms ease-in-out forwards;

  a {
    height: 100%;

    .side-title {
      position: relative;
      .over {
        width: 100%;
        height: 100%;
        background-color: #0e0e0e0e;
        position: absolute;
        top: 0px;
        z-index: 99;
      }
      img {
        object-fit: contain;
        width: 300px;
        max-height: 200px;
        z-index: 1;
      }
    }
    h4 {
      color: black;
      font-size: 1.2rem;
      margin-block: 1rem;
      z-index: 99;
      padding-inline: 20px;
    }
    p {
      color: black;
      padding-inline: 20px;

      .value {
        text-decoration: line-through;
        color: rgba(0, 0, 0, 0.507);
      }
      .descont {
        color: #d84366;
      }
    }
  }
}

.hidden {
  animation: hiddenSide 500ms 500ms ease-in-out forwards;
}

@keyframes slide {
  0% {
    right: -300px;
  }
  100% {
    right: 0px;
  }
}

@keyframes hiddenSide {
  0% {
    right: 0px;
  }
  100% {
    right: -300px;
  }
}
