.Container-shopping {
  p {
    font-size: 1.25rem;
    text-align: center;
  }
  .titleProducts {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }
  a {
    font-size: 1rem;
    width: 100%;
  }
}
