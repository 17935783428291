@import "../../assets/styles/base/variables";

.container-navbar {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid black;

  ul,
  .navbar-search {
    display: flex;
    align-items: center;
  }

  ul {
    display: flex;

    li {
      margin: 10px;
    }
  }

  .navbar-search {
    position: relative;

    width: 290px;
    height: 36px;
    border-radius: 28px;
    outline: none;
    border: 1px solid rgba(247, 245, 245, 0.384);
    background-color: transparent;
    padding-inline-start: 10px;

    input {
      width: 250px;
      padding-left: 0px;
      border-radius: inherit;
      background-color: transparent;
      outline: none;
      border: none;
      color: white;
    }
  }
}

@media (max-width: 700px) {
  .container-navbar {
    ul {
      padding: 0;
    }
    .navbar-search {
      width: 150px;

      input {
        width: 93px;
        padding-left: 0px;
        border-radius: inherit;
        outline: none;
        border: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .container-navbar {
    flex-wrap: wrap;
    padding-block-end: 1rem;
    height: max-content;
    .navbar-list {
      ul {
        margin: inherit;
        width: 100%;
      }
    }
    .navbar-search {
      width: 80%;
    }
  }
}
