.container-section {
  background-color: white;

  border-radius: 8px 0 0 8px;

  a {
    margin-bottom: 10px;
    background-color: white;
    img {
      width: 100%;
      height: 230px;
      object-fit: fill;
    }
    .description {
      position: relative;
      background-color: white;

      h4 {
        color: black;
        font-size: 1.2rem;
        margin-block-end: 1rem;
        margin-top: 0;
        z-index: 99;
        padding-inline: 20px;
      }
      p {
        color: black;
        padding-inline: 20px;

        .value {
          text-decoration: line-through;
          color: rgba(0, 0, 0, 0.507);
        }
        .descont {
          color: #59c00b;
          font-size: 0.8rem;
        }
      }
    }
  }
}
