@import "./variables";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $primary;
  color: #fff;
  overflow-x: hidden;
}

a,
li {
  list-style-type: none;
  color: white;
  text-decoration: none;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

div,
ul {
  &::-webkit-scrollbar {
    display: none;
  }
}

header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: black;
}
